<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="时间">
                <a-range-picker
                  @change="time"
                  format="YYYY-MM-DD HH:mm"
                  :show-time="{ format: 'HH:mm' }">
                  <template slot="dateRender" slot-scope="current">
                    <div class="ant-calendar-date" :style="getCurrentStyle(current)" >
                      {{ current.date() }}
                    </div>
                  </template>
                </a-range-picker>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="订单号">
                <a-input v-model="queryParam.order_no" placeholder="请选择" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="商品类型">
                <a-select v-model="queryParam.sell_type" placeholder="请选择" allow-clear>
                  <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.goods_sell_type">{{ val }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="来源">
                <a-select v-model="queryParam.source" placeholder="请选择" allow-clear>
                  <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.pick_list_order_source">{{ val }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-button type="primary" @click="loadData">查询</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <a-spin tip="加载中..." :spinning="loading" v-if="orderStatisticsResource.length > 0">
        <table border="0" cellspacing="0" cellpadding="0">
          <tr>
            <th>订单日期</th>
            <th>订单号</th>
            <th>供应商名称</th>
            <th>门店名称</th>
            <th>商品名称</th>
            <th style="width: 80px;">商品类型</th>
            <th>商品条形码</th>
            <th>优惠金额</th>
            <th style="width: 80px;">销售数量</th>
            <th>实收金额</th>
            <th>销项税额</th>
            <th>业务收入(不含税)</th>
            <th>成本（不含税）</th>
            <th>销售毛利</th>
            <th>商品毛利率</th>
          </tr>
          <tr v-for="(item, index) in orderStatisticsResource" :key="index">
            <td style="width: 140px; font-size: 14px;">
              {{ item['pick_list__done_time'].replaceAll('T', ' ') }}
            </td>
            <td style="width: 180px; font-size: 14px;">
              {{ item['pick_list__order_no'] }}
            </td>
            <td>
              {{ item['goods_purchase__corporation__name'] }}
            </td>
            <td>
              {{ item['pick_list__warehouse__name'] }}
            </td>
            <td>
              {{ item['goods_name'] }}
            </td>
            <td style="width: 80px;">
              {{ goods_sell_type[item['goods_purchase__sell_type']] }}
            </td>
            <td>
              {{ item['goods_purchase__barcode'] }}
            </td>
            <td class="text_content_right">
              {{ (Math.round((item['original_price'] - item['price']) * item['count']) / 100).toFixed(2) }}
            </td>
            <td style="text-align: right; padding-right: 10px; width: 80px;">
              {{ item['count'] }}
            </td>
            <td class="text_content_right">
              {{ (Math.round(item['price'] * item['count']) / 100).toFixed(2) }}
            </td>
            <td class="text_content_right">
              {{ Math.round(item['price'] * item['count']) / (1 + item['goods_purchase__sell_tax_rate']) * item['goods_purchase__sell_tax_rate'] }}
            </td>
            <td class="text_content_right">
              {{ (((Math.round(item['price'] * item['count'])) - (Math.round(item['price'] * item['count']) / (1 + item['goods_purchase__sell_tax_rate']) * item['goods_purchase__sell_tax_rate'])) / 100).toFixed(2) }}
            </td>
            <td class="text_content_right">
              {{ (((item['price'] * item['count']) - (item['price'] * item['count'] / (1 + item['goods_purchase__buy_tax_rate']))) / 100).toFixed(2) }}
            </td>
            <td class="text_content_right">
              {{ (item['price'] * item['count']) - (item['goods_purchase__price'] * item['count']) }}
            </td>
            <td>{{ (((item['price'] * item['count']) - (item['goods_purchase__price'] * item['count'])) / (item['price'] * item['count'])) * 100 + '%' }}</td>
          </tr>
          <tr>
            <td colspan="8">合计</td>
            <td style="text-align: right; padding-right: 10px; width: 80px;">{{ this.orderStatisticsResource.reduce((sum, item) => sum + item['count'], 0) }}</td>
            <td class="text_content_right">{{ this.orderStatisticsResource.reduce((sum, item) => sum + item['price'] * item['count'], 0) | moneyToYuan }}</td>
            <td class="text_content_right">{{ this.orderStatisticsResource.reduce((sum, item) => sum + Math.round(item['price'] * item['count']) / (1 + item['goods_purchase__sell_tax_rate']) * item['goods_purchase__sell_tax_rate'], 0) }}</td>
            <td class="text_content_right">{{ this.orderStatisticsResource.reduce((sum, item) => sum + (((Math.round(item['price'] * item['count'])) - (Math.round(item['price'] * item['count']) / (1 + item['goods_purchase__sell_tax_rate']) * item['goods_purchase__sell_tax_rate']))), 0) | moneyToYuan}}</td>
            <td class="text_content_right">{{ this.orderStatisticsResource.reduce((sum, item) => sum + (((item['price'] * item['count']) - (item['price'] * item['count'] / (1 + item['goods_purchase__buy_tax_rate'])))), 0) | moneyToYuan}}</td>
            <td>-</td>
            <td>-</td>
          </tr>
        </table>
      </a-spin>
    </a-card>
  </div>
</template>

<script>
import { STable } from '@/components'
import { doRefund, orderToExamine, orderStatisticsList } from '@/api/applet_shopmall_order'
import { user_list } from '@/api/user'
export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      tag_value: '',
      orderStatisticsResource: [],
      createUsers: [],
      userlist: [],
      goods_sell_type: this.$Dictionaries.goods_sell_type,
      tip: '',
      refund_status_visible: false,
      select_status_data: '3',
      refund: 0,
      refund_reason: '',
      loading: false,
      confirmLoading: false,
      order_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {
        total_count: 0,
        total_price: 0
      },
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '订单日期',
          ellipsis: true,
          dataIndex: 'pick_list__done_time'
        },
        {
          title: '订单号',
          ellipsis: true,
          dataIndex: 'pick_list__order_no'
        },
        {
          title: '供应商名称',
          ellipsis: true,
          dataIndex: 'goods_purchase__corporation__name'
        },
        {
          title: '门店名称',
          ellipsis: true,
          dataIndex: 'pick_list__warehouse__name'
        },
        {
          title: '商品名称',
          ellipsis: true,
          dataIndex: 'goods_name'
        },
        {
          title: '商品类型',
          ellipsis: true,
          dataIndex: 'goods_purchase__sell_type',
          customRender: (text) => this.$Dictionaries.goods_sell_type[text]
        },
        {
          title: '商品条形码',
          ellipsis: true,
          dataIndex: 'goods_purchase__barcode'
        },
        {
          title: '优惠金额',
          ellipsis: true,
          dataIndex: 'original_price',
          customRender: (text, record) => (Math.round((text - record.price) * record.count) / 100).toFixed(2)
        },
        {
          title: '销售数量',
          ellipsis: true,
          dataIndex: 'count'
        },
        {
          title: '实收金额',
          dataIndex: 'price',
          align: 'center',
          customRender: (text, record) => (Math.round(text * record.count) / 100).toFixed(2)
        },
        {
          title: '销项税额',
          dataIndex: 'goods_purchase__sell_tax_rate',
          align: 'center',
          customRender: (text, record) => Math.round(record.price * record.count) / (1 + text) * text
        },
        {
          title: '业务收入(不含税)',
          dataIndex: 'goods_purchase__batch',
          align: 'center',
          customRender: (text, record) => (((Math.round(record.price * record.count)) -
            (Math.round(record.price * record.count) / (1 + record.goods_purchase__sell_tax_rate) * record.goods_purchase__sell_tax_rate)) / 100).toFixed(2)
        },
        {
          title: '成本（不含税）',
          dataIndex: 'goods_purchase__buy_tax_rate',
          align: 'center',
          customRender: (text, record) => (((record.price * record.count) - (record.price * record.count / (1 + text))) / 100).toFixed(2)
        },
        {
          title: '销售毛利',
          dataIndex: 'goods_purchase__price',
          align: 'center',
          customRender: (text, record) => (record.price * record.count) - (text * record.count)
        },
        {
          title: '商品毛利率',
          dataIndex: 'goods_purchase__corporation_id',
          align: 'center',
          customRender: (text, record) => ((record.price * record.count) - (record.goods_purchase__price * record.count) / (record.price * record.count)) * 100 + '%'
        }
      ]
    }
  },
  filters: {
    moneyToYuan (value) {
      return (value / 100).toFixed(2)
    }
  },
  methods: {
    handleDetail (record) {
      console.log(record.id)
      this.$router.push({ path: 'order/detail', query: { id: record.id } })
    },
  //  手动补订单库存数量
    onDeliverFirstChange (e) {
      console.log(e)
      if (!e.target.checked) {
        this.queryParam.self_delivery_first = undefined
      }
      this.$refs.table.refresh(true)
    },
    select_status () {
      if (this.select_status_data === '请选择') {
        this.$message.warning('请选择')
        return
      }
      const param = {
        status: this.select_status_data,
        order_id: this.order_id
      }
      if (this.select_status_data === '5') {
        param.refund = this.refund
      }
      this.confirm_loading = true
      orderToExamine(param).then(({ data }) => {
        console.log(data)
        this.select_status_data = '请选择'
        this.refund = 0
        this.order_id = 0
        this.select_status_visible = false
        this.confirm_loading = false
        this.$refs.table.refresh(true)
      })
    },
    select_refund () {
      if (this.refund === 0) {
        this.$message.warning('请输入退款金额')
        return
      }
      const param = {
        refund: Math.round(this.refund * 100),
        reason: this.refund_reason,
        order_id: this.order_id
      }

      doRefund(param).then(({ data }) => {
        console.log(data)
        this.refund = 0
        this.order_id = 0
        this.reason = ''
        this.refund_status_visible = false
        this.$refs.table.refresh(true)
      })
    },
    isRefund (status) {
      return status === 11 || status === 2 || status === 3
    },
    // 订单审核
    handle_to_examine (record) {
      this.select_status_visible = true
      // 2 审核通过，5 审核不通过
      this.order_id = record.id
    },
    // 订单退款
    handleRefund (record) {
      // this.refund_status_visible = true
      // // 2 审核通过，5 审核不通过
      // this.order_id = record.id
      this.$router.push({ path: 'order/detail_refund', query: { id: record.id } })
    },
    handleSelectChange (value) {
      this.select_status_data = value
      console.log(this.select_status_data, typeof this.select_status_data)
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      if (dateStrings[0] === '') {
        this.queryParam.start_time = undefined
        this.queryParam.end_time = undefined
        return
      }
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    // 加载数据方法 必须为 Promise 对象
    loadData () {
      if (this.queryParam.start_time === undefined || this.queryParam.end_time === undefined) {
        this.$message.warning('请选择开始时间和结束时间')
      } else {
        this.loading = true
        orderStatisticsList(Object.assign({}, this.queryParam))
          .then((res) => {
            console.log('订单管理：', res)
            // this.mdl = res.data
            this.orderStatisticsResource = res.data
          }).finally(() => {
            this.loading = false
        })
      }
    },
    onChange (value) {
      console.log('onChange', value)
      if (value === undefined) {
        this.queryParam.create_by_id = undefined
      }
    },
    onSearch (searchText) {
      user_list({ nickname: searchText, role: 1 }).then((res) => {
        const reslut = res.data.entries || []
        this.userlist = reslut
        if (reslut.length === 0) {
          this.tip = '未找到您输入的用户'
        } else {
          this.tip = ''
        }
        this.createUsers = !searchText ? [] : reslut.map((item) => item.nickname)
      })
    },
    onSelect (value) {
      const filter = this.userlist.filter((item) => (item.nickname === value))
      console.log(value, '我是选择的值', filter)
      if (filter) {
        const user_id = filter[0].id + ''
        this.queryParam.create_by_id = user_id
      }
    }
  }
}
</script>

<style scoped>
table {
  table-layout: fixed;
  border: 1px solid #ddd;
}

th, td {
  border-right: 1px solid #ddd;
  border-bottom: 0.5px solid #ddd;
  vertical-align: middle;
  text-align: center;
  padding: 12px 0;
  color: #666;
  font-size: 14px;
  width: 120px;
  max-width: 180px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}

th {
  font-weight: normal;
}

.text_right {
  text-align: right;
  padding-right: 10px;
}

.other_bg {
  background-color: #f5f5f5
}

.text_content_right {
  text-align: right;
  padding-right: 10px;
}
</style>
